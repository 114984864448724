<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="advantage">
    <div class="advantage-text">
      <h2 id="shuziren">数字人应用优势</h2>
      <p>提供低成本，高效率的线上获客工具</p>
    </div>
    <div class="mainContent-img">
      <!-- 图一 -->
      <div class="mainContent-img-box1">
        <img src="../images/advantage1.png" alt="数字人例1" />
        <div class="overlay"></div>
        <div class="mainContent-content hidden">
          <p>降本增效</p>
          <span></span>
        </div>
        <!-- 遮罩层 -->
        <div class="ballcontent">
          <div class="mainContent-content display">
            <p>降本增效</p>
            <span></span>
          </div>
          <div class="ballcontent-bottom">
            <p>给企业商户提供线上低成本获客</p>
            <p>给创业者增加创业学习机会</p>
            <p>降低真人直播门槛，打破直播时间限制</p>
          </div>
        </div>
      </div>
      <!-- 图二 -->
      <div class="mainContent-img-box1">
        <img src="../images/advantage2.png" alt="数字人例2" />
        <div class="overlay"></div>
        <div class="mainContent-content hidden">
          <p>应用广泛</p>
          <span></span>
        </div>
        <!-- 遮罩层 -->
        <div class="ballcontent">
          <div class="mainContent-content display">
            <p>应用广泛</p>
            <span></span>
          </div>
          <div class="ballcontent-bottom">
            <p>跨行业多领域的商业应用</p>
            <p>
              可按照不同的主题、风格、语音特点和人设形象进行定制和创作，满足用户在特定的场合和目标群体的需求。
            </p>
            <p>可充当产品讲解.导购.下单做你的24小时虚拟员工。</p>
          </div>
        </div>
      </div>
      <!-- 图三 -->
      <div class="mainContent-img-box1">
        <img src="../images/advantage3.png" alt="数字人例3" />
        <div class="overlay"></div>
        <div class="mainContent-content hidden">
          <p>智能高效</p>
          <span></span>
        </div>
        <!-- 遮罩层 -->
        <div class="ballcontent">
          <div class="mainContent-content display">
            <p>智能高效</p>
            <span></span>
          </div>
          <div class="ballcontent-bottom">
            <p>强大的AI技术结合</p>
            <p>可根据用户弹幕理解，推送适合产品，提高直播成交的转化率。</p>
            <p>
              三秒生成批量优质短视频标题及内容，打造账号视频矩阵，提高账号曝光量。
            </p>
          </div>
        </div>
      </div>
      <!-- 图四 -->
      <div class="mainContent-img-box1">
        <img src="../images/advantage4.png" alt="数字人例4" />
        <div class="overlay"></div>
        <div class="mainContent-content hidden">
          <p>形象风格</p>
          <span></span>
        </div>
        <!-- 遮罩层 -->
        <div class="ballcontent">
          <div class="mainContent-content display">
            <p>形象风格</p>
            <span></span>
          </div>
          <div class="ballcontent-bottom">
            <p>多元化的行业职业数字人</p>
            <p>给各行各业提供对标职业数字人，满足同行业的粉丝群体喜好。</p>
            <p>不需要场地租赁，化妆师，拥有高效亲和的语言表达能力。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.advantage {
  height: 654px;
  margin-top: 50px;
  .advantage-text {
    width: 100%;
    text-align: center;
    h2 {
      // width: 252px;
      width: 100%;
      height: 36px;
      font-size: 36px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #000000;
      line-height: 36px;
      margin: 0 auto;
      margin-bottom: 30px;
    }
    p {
      // width: 384px;
      width: 100%;
      height: 24px;
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #a8a9aa;
      line-height: 24px;
      margin: 0 auto;
      margin-bottom: 50px;
    }
  }
}

.mainContent-img {
  display: flex;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  position: absolute;
  left: 230px;
  .mainContent-content {
    position: absolute;
    top: 285px;
    left: 127px;
    transition: all 0.5s;
    p {
      width: 100px;
      height: 24px;
      font-size: 24px;
      font-family: PingFang SC-Heavy, PingFang SC;
      font-weight: 800;
      color: #ffffff;
      line-height: 24px;
      margin-bottom: 30px;
    }
    span {
      display: block;
      margin: 0 auto;
      width: 40px;
      height: 10px;
      background: #d9d9d9;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      position: absolute;
      left: 50%;
      transform: translate(-20px, 0);
    }
  }
}
.mainContent-img-box1 {
  position: relative;
  width: 350px;
  height: 450px;
  overflow: hidden;
  margin-right: 20px;
  border-radius: 20px;
}
.mainContent-img-box1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  border: 1px solid #ccc;
}
.mainContent-img-box1 .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
}
.mainContent-img-box1 .mainContent-content {
  z-index: 3;
}
.ballcontent {
  width: 400px;
  height: 455px;
  transform: translate(0px, 100%);
  background-color: #000000;
  opacity: 0;
  transition: all 1s;
  z-index: 5;
  .ballcontent-bottom {
    width: 100%;
    display: block;
    font-size: 16px;
    color: white;
    position: absolute;
    bottom: 10px;
    left: 0;
    p {
      display: block;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 12px;
      white-space: normal;
      padding: 0 20px 0;
      padding-right: 60px;
    }
  }
}
.mainContent-img-box1:hover .ballcontent {
  transform: translate(0, -100%);
  opacity: 0.5;
}
.mainContent-img-box1:hover .hidden {
  opacity: 0;
}
.display {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, -300%);
}
</style>
