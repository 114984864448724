<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="paymentImg">
    <!-- 文字区域 -->
    <div class="payment-text">
      <h2 id="shulianbao">数联宝让支付产生价值</h2>
      <p>让天下的生意更好做</p>
    </div>
    <!-- 图片区域 -->
    <div class="payment-img">
      <img src="../images/payment.png" alt="" />
      <div class="payment-img-small">
        <ul>
          <li>
            <div class="payment-img-box">
              <img src="../images/payment5.png" alt="" />
            </div>
            <p>支持信用卡无息分期</p>
          </li>
          <li>
            <div class="payment-img-box">
              <img src="../images/payment1.png" alt="" />
            </div>
            <p>支持央行DCEP支付</p>
          </li>
          <li>
            <div class="payment-img-box">
              <img src="../images/payment2.png" alt="" />
            </div>
            <p>支持跨境支付收款</p>
          </li>
          <li>
            <div class="payment-img-box">
              <img src="../images/payment3.png" alt="" />
            </div>
            <p>支持线上全渠道收款结算</p>
          </li>
          <li>
            <div class="payment-img-box">
              <img src="../images/payment4.png" alt="" />
            </div>
            <p>支持区块链上支付和智能合约交易</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
body {
  position: relative;
}
.paymentImg {
  position: relative;
  left: -30%;
  width: 1920px;
  height: 1174px;
  background: #f5f7fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  text-align: center;
  margin: 0 auto;
  .payment-text {
    width: 100%;
    text-align: center;
    h2 {
      // width: 524px;
      width: 100%;
      height: 48px;
      font-size: 48px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #000000;
      line-height: 48px;
      letter-spacing: 4px;
      margin: 0 auto;
      padding-top: 82px;
      margin-bottom: 30px;
    }
    p {
      // width: 288px;
      width: 100%;
      height: 32px;
      font-size: 32px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 32px;
      margin: 0 auto;
      margin-bottom: 33px;
    }
  }
}
.payment-img {
  position: relative;
  img {
    width: 1572px;
    height: 679px;
  }
}
.payment-img-small {
  width: 1714px;
  margin: 0 auto;
  height: 203px;
  background: #f5f7fa;
  box-shadow: 0px 0px 0px 0px rgba(31, 8, 84, 0.03),
    0px 3px 6px 0px rgba(31, 8, 84, 0.03),
    0px 10px 10px 0px rgba(31, 8, 84, 0.03),
    0px 23px 14px 0px rgba(31, 8, 84, 0.02),
    0px 42px 17px 0px rgba(31, 8, 84, 0), 0px 65px 18px 0px rgba(31, 8, 84, 0);
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  border: 3px solid #ffffff;
  position: absolute;
  bottom: -180px;
  left: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ul {
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
    li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: 120px;
      font-size: 17px;
      p{
        font-size: 16px !important;
      }
    }
  }
}
.payment-img-box {
  width: 84px;
  height: 84px;
  background: #ffffff;
  opacity: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  img {
    width: 60%;
    height: auto;
  }
}
</style>
