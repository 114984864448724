<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>新的页面</div>
</template>

<script setup lang="ts">

</script>

<style scoped>

</style>