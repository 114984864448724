<template>
  <div class="sceneApp">
    <!-- 文字区域 -->
    <div class="sceneApp-text">
      <h2>场景应用</h2>
      <p>实现内容解说与产品展示同步化播放</p>
    </div>
    <!-- 图片区域 -->
    <div class="sceneApp-img">
      <div class="sceneApp-img-box">
        <img src="../images/sceneApp3.png" alt="" />
        <div class="sceneApp-img-text">
          <h2 class="hh2">影视行业</h2>
          <p>
            数字替身特效可以帮助导演实现拍摄中无法表现的内容和效果，已成为特效商业大片拍摄中的重要技术手段和卖点。角色有数字替身。
          </p>
        </div>
      </div>
      <div class="sceneApp-img-box">
        <img src="../images/sceneApp2.png" alt="" />
        <div class="sceneApp-img-text">
          <h2 class="hh2">文化旅游</h2>
          <p>
            博物馆、科技馆、主题公园、名人故居等虚拟小剧场、虚拟导游、虚拟解说员。角色有虚拟导游、虚拟解说员。
          </p>
        </div>
      </div>
      <div class="sceneApp-img-box">
        <img src="../images/sceneApp1.png" alt="" />
        <div class="sceneApp-img-text">
          <h2 class="hh2">电商直播</h2>
          <p>
            数字人直播解决方案解决商家直播成本高、不了解运营、不了解直播的痛点，可以帮助商家降本增效，无需真人出镜，让本地生活直播平民化。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss">
.sceneApp {
  position: relative;
  height: 620px;
  .sceneApp-text {
    width: 100%;
    text-align: center;
    h2 {
      // width: 144px;
      width: 100%;
      height: 36px;
      font-size: 36px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #000000;
      line-height: 36px;
      margin: 0 auto;
    }
    p {
      // width: 384px;
      width: 100%;
      height: 24px;
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #a8a9aa;
      line-height: 24px;
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }
}
.sceneApp-img {
  position: absolute;
  left: -125px;
  display: flex;
  .sceneApp-img-box {
    width: 444px;
    height: 351px;
    background: #f2f2f2;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    margin-right: 19px;
    padding: 15px 15px 0 15px;
    img {
      border-radius: 10px 10px 10px 10px;
      opacity: 1;
      width: 100%;
    }
    .sceneApp-img-text {
      padding: 30px 0px 0px 15px;
      width: 100%;
      // text-align:end
      .hh2 {
        // width: 96px;
        width: 100%;
        height: 24px;
        font-size: 24px !important;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #282828;
        line-height: 24px;
        margin-bottom: 20px;
      }
      p {
        width: 429px;
        height: 77px;
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #9d9d9d;
        line-height: 26px;
      }
    }
  }
}
</style>
