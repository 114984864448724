<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="contact" id="lianxi">
    <div class="logo">
      <img src="../images/biglog.png" alt="" />
      <p>展市元宇宙</p>
    </div>
    <div class="phone">
      <p>联系我们</p>
      <span></span>
      <p>商务合作：0571-88016827</p>
      <p>公司地址：浙江省杭州市滨江区兴耀科技园3号楼404</p>
      <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2021020985号-1 </a>
    </div>
    <div class="appxz">
      <img src="../images/下载.png" alt="下载app">
      <p>APP下载</p>
    </div>
    <div class="QRcode">
      <p class="QRcode-text">扫码了解更多</p>
      <div class="QRcode-wx">
        <div class="QRcode-qywx">
          <img src="../images/qywx.png" alt="展市元宇宙微信公众号" />
          <p>微信公众号</p>
        </div>
        <div class="QRcode-wxx">
          <img src="../images/wx.png" alt="展市元宇宙企业微信号" />
          <p>企业微信号</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.contact {
  width: 1920px;
  height: 444px;
  background: #030619;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  position: absolute;
  left: 0;
}
.logo {
  width: 164px;
  height: 164px;
  padding: 120px 0 170px 108px;
  img {
    width: 100%;
  }
  p {
    width: 160px;
    height: 32px;
    font-size: 32px;
    font-family: PingFang SC-Heavy, PingFang SC;
    font-weight: 800;
    color: #bbbbbb;
    line-height: 32px;
  }
}
.phone {
  height: 20px;
  font-size: 20px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #bbbbbb;
  line-height: 20px;
  position: absolute;
  top: 153px;
  left: 350px;
  span {
    display: block;
    width: 39px;
    height: 7px;
    background: #d9d9d9;
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    margin: 40px 0 30px 0;
  }
  a{
    text-decoration: none;
    color: #bbbbbb;
    margin: 10px 0px;
  }
  p{
    margin: 10px 0px;
  }
}
.phone p:last-child {
  margin-top: 30px;
}
.QRcode {
  position: absolute;
  top: 133px;
  left: 1449px;
  height: 20px;
  font-size: 20px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #bbbbbb;
  line-height: 20px;
  text-align: center;
}
.QRcode-text{
  transform: translateX(-85px);
  margin-bottom: 39px;
}
.QRcode-wx{
  display: flex;
  justify-content: center;
  align-items: center;
}
.QRcode-qywx{
  margin-right: 56px;
}
.QRcode-wx img{
  width: 113px;
  margin-bottom: 34px;
}

.appxz{
  width: 113px;
  height: 113px;
  position: absolute;
  top: 192px;
  right: 530px;
  text-align: center;
  img {
    width: 100%;
    margin-bottom: 32px;
    border-radius: 10px;
  }
  p {
    font-size: 20px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #bbbbbb;
  line-height: 20px;
  }
}
</style>
