<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="case">
    <div class="case-text">
      <h2 id="guanyu">展市元宇宙门店案例展示</h2>
      <p>多元化业务形态和雄厚的技术实力，成功服务万千线下门店</p>
    </div>
    <div class="case-img">
      <div class="case-img1">
        <img src="../images/case1.png" alt="成功案例别克4s店" />
        <div class="hidden">
          <h3>别克4s店</h3>
        </div>
      </div>
      <div class="case-img1">
        <img src="../images/case2.png" alt="成功案例运营中间云展厅" />
        <div class="hidden">
          <h3>运营中间云展厅</h3>
        </div>
      </div>
      <div class="case-img1">
        <img src="../images/case3.png" alt="成功案例阿迪达斯大中华区总部" />
        <div class="hidden">
          <h3>阿迪达斯大中华区总部</h3>
        </div>
      </div>
      <div class="case-img1">
        <img src="../images/case4.png" alt="成功案例火星工厂滨江店" />
        <div class="hidden">
          <h3>火星工厂滨江店</h3>
        </div>
      </div>
      <div class="case-img1">
        <img src="../images/case5.png" alt="成功案例罗莱超软床品" />
        <div class="hidden">
          <h3>罗莱超软床品</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.case {
  height: 654px;
  position: relative;
  left: 0;
  bottom: 0;
  // margin-bottom: 100px;
  .case-text {
    width: 100%;
    text-align: center;
    h2 {
      // width: 396px;
      width: 100%;
      height: 32px;
      font-size: 36px;
      font-family: PingFang SC-Heavy, PingFang SC;
      font-weight: 800;
      color: #000000;
      line-height: 32px;
      margin: 0 auto;
      margin-top: 100px;
      margin-bottom: 30px;
    }
    p {
      // width: 624px;
      width: 100%;
      height: 24px;
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #a8a9aa;
      line-height: 24px;
      margin: 0 auto;
      margin-bottom: 54px;
    }
  }
}
.case-img {
  position: absolute;
  left: -260px;
  display: flex;
  .case-img1 {
    width: 320px;
    height: 450px;
    background: #e38686;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    margin-right: 30px;
    overflow: hidden;
    img {
      border-radius: 20px;
      overflow: hidden;
      width: 100%;
    }
  }
}
.hidden {
  width: 100%;
  height: 20%;
  background-color: rgba(0, 0, 0, 0.4);
  transform: translateY(-103%);
  display: flex;
  justify-content: center;
  align-items: center;
  h3 {
    height: 32px;
    font-size: 30px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ffffff;
    line-height: 32px;
    margin: 0 auto;
    overflow: hidden;
  }
}
</style>
