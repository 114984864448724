<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="cooperate-box">
    <div class="cooperate-text">
      <h2>合作企业</h2>
      <p>万千企业正在使用展市宇宙</p>
    </div>
    <div class="cooperate-img-box">
      <div class="cooperate-img">
        <img src="../images/cooperate1.png" alt="" style="z-index: 1" />
        <img src="../images/cooperateBGC.png" alt="" />
      </div>
      <div class="cooperate-img">
        <img src="../images/cooperate2.png" alt="" style="z-index: 1" />
        <img src="../images/cooperateBGC.png" alt="" />
      </div>
      <div class="cooperate-img">
        <img src="../images/cooperate3.png" alt="" style="z-index: 1" />
        <img src="../images/cooperateBGC.png" alt="" />
      </div>
      <div class="cooperate-img">
        <img src="../images/cooperate4.png" alt="" style="z-index: 1" />
        <img src="../images/cooperateBGC.png" alt="" />
      </div>
      <div class="cooperate-img nobian">
        <img src="../images/cooperate5.png" alt="" style="z-index: 1" />
        <img src="../images/cooperateBGC.png" alt="" />
      </div>
      <div class="cooperate-img">
        <img src="../images/cooperate6.png" alt="" style="z-index: 1" />
        <img src="../images/cooperateBGC.png" alt="" />
      </div>
      <div class="cooperate-img">
        <img src="../images/cooperate7.png" alt="" style="z-index: 1" />
        <img src="../images/cooperateBGC.png" alt="" />
      </div>
      <div class="cooperate-img">
        <img src="../images/cooperate8.png" alt="" style="z-index: 1" />
        <img src="../images/cooperateBGC.png" alt="" />
      </div>
      <div class="cooperate-img">
        <img src="../images/cooperate9.png" alt="" style="z-index: 1" />
        <img src="../images/cooperateBGC.png" alt="" />
      </div>
      <div class="cooperate-img nobian">
        <img src="../images/cooperate10.png" alt="" style="z-index: 1" />
        <img src="../images/cooperateBGC.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.cooperate-box {
  width: 100%;
  height: 414px;
  background-color: #fff;
  .cooperate-text {
    width: 100%;
    text-align: center;
    margin-bottom: 52px;
    h2 {
      // width: 144px;
      width: 100%;
      height: 36px;
      font-size: 36px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #000000;
      line-height: 36px;
      margin: 0 auto;
      margin-bottom: 30px;
    }
    p {
      // width: 288px;
      width: 100%;
      height: 24px;
      font-size: 24px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #a8a9aa;
      line-height: 24px;
      margin: 0 auto;
    }
  }
}
.cooperate-img-box {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cooperate-img {
  position: relative;
  width: 200px;
  height: 79px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 40px;
  margin-bottom: 50px;
  img {
    position: absolute;
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
}
.nobian {
  margin-right: 0;
}
</style>
